/**
 * ARMs配置
 */
import * as BrowserLogger from '@arms/js-sdk';
import { isRemote, isProduction, version } from '../env';
import { getPageName } from './get-page-name';

const PID = 'c17fpe3hwh@1951c92bd5cfad8';
// const PID = 'c17fpe3hwh@d710cba6bb25950';
let logger: { setPage: (arg0: string) => any; error: (e: any) => void; } | undefined;

export const updatePage = () => {
  logger && logger.setPage('default');
};

const initLogger = () => {
  logger = BrowserLogger.singleton({
    pid: PID,
    appType: 'web',
    imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?', // 上报地址
    sample: 1, // 采样率
    behavior: true,
    enableSPA: true,
    parseHash: getPageName,
    useFmp: true,
    sendResource: true,
    enableLinkTrace: true,
    page: getPageName(),
    release: version,
    environment: isRemote ? (isProduction ? 'prod' : 'pre') : 'local',
  });
};

initLogger();

export const logError = (error: any) => {
  if (logger) {
    logger.error(error);
  }
};