import { useEffect, useMemo, useRef, useState } from 'react';
import './index.less';
import { useMemoizedFn } from 'ahooks';
interface IVerifyInput{
  value?: string;
  className?: string;
  onChange?:(value:string)=>void;
}
const list = new Array(6).fill(1);
export const VerifyInput = ({className,onChange,value}:IVerifyInput)=>{
  const inputRef = useRef<HTMLInputElement>(null);
  const [verify, setVerify] = useState('');
  const [focus, setFocus] = useState(false);
  const change = (value:string)=>{
    const v = String(value).replace(/[^\d]/g,'').substring(0, 6);
    setVerify(v);
    onChange && onChange(v);
  };
  useEffect(()=>{
    if (value !== undefined){
      setVerify(value);
    }
  }, [value]);

  useEffect(() => {
    inputRef.current?.focus();
    setFocus(true);
  }, []);

  const setToEnd = useMemoizedFn(()=>{
    const input = inputRef.current;
    if (input){
      input.setSelectionRange(
        input.value.length,
        input.value.length
      );
    }
  });

  return <div className={'verify-wrapper' + (className ? ` ${className}` : '')}>
    <div className='verify-content' onClick={()=>{
      inputRef.current?.focus();
      setFocus(true);
    }}>
      {
        list.map((_,index)=><div 
          className={'verify-number' + (focus && (verify.length === index || (verify.length === 6 && index === 5)) ? ' verify-cursor' : '')} 
          data-off={focus && verify.length === 6 && index === 5}
          key={index}>{verify.charAt(index)}</div>)
      }
      <div className='space'></div>
    </div>
    <input
      ref={inputRef}
      value={verify}
      className='verify-input'
      onBlur={()=>{
        setFocus(false);
      }}
      onKeyDown={(e)=>{
        setToEnd();
      }}
      onChange={e => {change(e.target.value);}}/>
  </div>;
};