
export const getSubPage = () => {
  const p = window.location.hash.split('?')[0].match(/[^#/]+/g);
  if (p && typeof p[0] === 'string') {
    const k = p[0];
    if (k === 'history' || k === 'training-list' || k === 'writing-list') {
      if (typeof p[1] === 'string') {
        return `${p[0]}-${p[1]}`;
      }
    }
    return p[0];
  }
  return 'default';
};

export const getPage = () => {
  const p = window.location.pathname.match(/[^/]+/);
  if (p && typeof p[0] === 'string') {
    return p[0];
  }
  return 'default';
};

export const getPageName = () => {
  return `[account][${getPage()}][${getSubPage()}]`;
};
