import Mask from '@/components/mask';
import Login from '@/components/login';
import { LogoIcon } from '@/components/common';
import bgImg from '@/assets/next_bg.jpeg';
import './index.less';
const Modal = () => {
  return (
    <div className="modal">
      <div
        className='bg'
        style={{
          backgroundImage: `url(${bgImg})`
        }}>
        <div className="mesh"></div>
      </div>

      <div className="logo">
        <LogoIcon />
      </div>

      <Mask><Login /></Mask>
    </div>
  );
};

export default Modal;
